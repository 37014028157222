import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import GetInTouch from './pages/GetInTouch';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/home',
      element: <DashboardLayout />,
      children: [{ path: '', element: <DashboardApp /> }],
    },
    {
      path: '/services',
      element: <DashboardLayout />,
      children: [{ path: '', element: <Products /> }],
    },
    {
      path: '/getintouch',
      element: <DashboardLayout />,
      children: [{ path: '', element: <GetInTouch /> }],
    },
    { path: '*', element: <Navigate to="/home" replace /> },
  ]);
}
