// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'home',
    path: '/home',
    icon: getIcon('ci:home-alt-fill'),
  },
  {
    title: 'services',
    path: '/services',
    icon: getIcon('ic:twotone-miscellaneous-services'),
  },
  {
    title: 'get in touch',
    path: '/getintouch',
    icon: getIcon('mdi:email-fast'),
  },
];

export default navConfig;
