// material
import { Box, Container, Typography } from '@mui/material';
// components
import faker from '@faker-js/faker';
import { FaComments } from 'react-icons/fa';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import { ProductList } from '../sections/@dashboard/products';
// mock

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const prod = [
    {
      id: faker.datatype.uuid(),
      cover: (
        <Box display="flex" justifyContent="center">
          <FaComments style={{ width: '80%', height: '80%', objectFit: 'cover' }} />
        </Box>
      ),
      name: 'Receive tailerd advice for you exact needs',
    },
    {
      id: faker.datatype.uuid(),
      cover: (
        <Box display="flex" justifyContent="center">
          <Iconify icon={'bxs:user-check'} width={'80%'} height={'80%'} />
        </Box>
      ),
      name: 'Expert IT consultants who speak in plain language',
    },
    {
      id: faker.datatype.uuid(),
      cover: (
        <Box display="flex" justifyContent="center">
          <Iconify icon={'vscode-icons:file-type-reactjs'} width={'80%'} height={'80%'} />
        </Box>
      ),
      name: 'Expertise in React',
    },
    {
      id: faker.datatype.uuid(),
      cover: (
        <Box display="flex" justifyContent="center">
          <Iconify icon={'vscode-icons:file-type-node'} width={'80%'} height={'80%'} />
        </Box>
      ),
      name: 'Expertise in Node JS',
    },
    {
      id: faker.datatype.uuid(),
      cover: (
        <Box display="flex" justifyContent="center">
          <Iconify icon={'logos:highcharts'} width={'80%'} height={'80%'} />
        </Box>
      ),
      name: 'Expertise in Highchart',
    },
  ];

  return (
    <Page title="Services">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Services
        </Typography>
        <Typography variant="h6" sx={{ mb: 5 }}>
          As part of our IT consultancy we offer support and guidance to enable your business to implement new and
          improved IT solutions and software.
        </Typography>

        <ProductList products={prod} />
      </Container>
    </Page>
  );
}
